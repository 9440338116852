<template>
	<div data-component="course-view">
		<article>
			<div data-element="type-and-level">
				<live-or-solo-badge
					:isLive="getIsLive"
					courseOrLesson="course"
				/>
				<experience-level-chart
					:minExperienceLevel="course.minExperienceLevel"
					:maxExperienceLevel="course.maxExperienceLevel"
					:label="true"
				/>
			</div>
			<router-link
				:to="getTeacherProfileRoute"
				data-element="teacher"
			>
				<icon
					icon="courses"
				/>
				<span>
					{{ getTeacherName }}
				</span>
			</router-link>
			<h1
				v-if="getCourseTitle"
				data-element="heading"
			>
				{{ getCourseTitle }}
			</h1>
			<p
				v-if="getDescription"
				data-element="subheading"
			>
				{{ getDescription }}
			</p>
			<div
				data-element="introduction"
				v-if="getIntroduction"
			>
				<h2>About this course</h2>
				<div
					data-element="introduction"
					v-html="getIntroduction"
				/>
			</div>
			<div
				data-element="main-cta"
				v-if="getShowJoinBtn"
			>
				<btn
					:text="getJoinBtnText"
					:ghost="true"
					size="xl"
					actionId="joinCourse"
					@actionClick="onActionClick"
				/>
			</div>
			<lesson-list
				:lessons="getLessons"
			/>
			<course-action-groups
				v-if="getCourseIsLoaded"
				:course="course"
			/>
		</article>
		<aside>
			<additional-info-list
				:info="getAdditionalInfo"
			/>
			<course-action-list
				v-if="getCourseIsLoaded"
				:course="course"
			/>
			<div
				data-element="badges"
				v-if="getCourseIsComplete"
			>
				<badge
					v-if="getCourseIsComplete"
					text="Course completed"
					icon="check"
				/>
			</div>
		</aside>
	</div>
</template>

<script>

	import LiveOrSoloBadge       from '@/components/ui/LiveOrSoloBadge';
	import ExperienceLevelChart  from '@/components/courses/ExperienceLevelChart';
	import Icon                  from '@/components/ui/Icon';
	import Badge                 from '@/components/ui/Badge';
	import Btn                   from '@/components/ui/Btn';
	import LessonList            from '@/components/courses/LessonList';
	import CourseActionGroups    from '@/components/courses/CourseActionGroups';
	import AdditionalInfoList    from '@/components/ui/additionalInfoList/AdditionalInfoList';
	import CourseActionList      from '@/components/courses/CourseActionList';
	import actionClick           from '@/mixins/actionClick';
	import routeParams           from '@/mixins/routeParams';
	import api                   from '@/services/api';
	import getStripe             from '@/services/stripe';
	import { courseCost, courseDiscountCost } from '@/formatters';

	export default {
		components: {
			LiveOrSoloBadge,
			ExperienceLevelChart,
			Icon,
			CourseActionGroups,
			Badge,
			Btn,
			AdditionalInfoList,
			CourseActionList,
			LessonList
		},
		mixins: [actionClick, routeParams],
		props: {
			course: {
				type: Object,
				default: () => ({})
			}
		},
		computed: {
			getIsLive () {
				return this.course.live;
			},
			getTeacherName () {
				return this.course?.teacherName;
			},
			getCourseTitle () {
				return this.course?.title;
			},
			getDescription () {
				return this.course?.description;
			},
			getIntroduction () {
				return this.course?.introduction;
			},
			getCostString () {
				if (this.getIsFree) {
					return 'free';
				}
				if (this.course?.costWithDiscount) {
					return courseDiscountCost(this.course);
				}
				return courseCost(this.course);
			},
			getIsFree () {
				return this.course.isFree;
			},
			getJoinBtnText () {
				if (this.getIsFree) {
					return 'Join now for free';
				}
				return `Buy now for ${this.getCostString}`;
			},
			getShowJoinBtn () {
				return this.getContext === 'browseCourses';
			},
			getCourseIsLoaded () {
				return this.course?.id !== undefined;
			},
			getTeacherProfileId () {
				return this.course?.teacherProfileId;
			},
			getTeacherProfileRoute () {
				if (!this.getTeacherProfileId) {
					return '';
				}
				return `/teachers/${this.getTeacherProfileId}`;
			},
			getLessons () {
				return this.course?.lessons;
			},
			getFocusString () {
				if (!this.course?.focus?.length) {
					return false;
				}
				return this.course.focus.join(', ');
			},
			getBiddingSystemsString () {
				if (this.course?.biddingSystems !== null &&
						!Array.isArray(this.course.biddingSystems)) {
					return false;
				}
				if (this.course.biddingSystems === null) {
					return 'None';
				}
				if (!this.course.biddingSystems?.length) {
					return 'Any';
				}
				return this.course.biddingSystems.join(', ');
			},
			getKeywords () {
				if (!this.course?.keywords?.length) {
					return false;
				}
				return this.course.keywords;
			},
			getAdditionalInfo () {
				return [
					(this.getBiddingSystemsString ?
						{
							heading: 'Systems',
							text: this.getBiddingSystemsString
						} : false
					),
					(this.getFocusString ?
						{
							heading: 'Focus',
							text: this.getFocusString
						} : false
					),
					(this.getKeywords ?
						{
							heading: 'Keywords',
							badges: this.getKeywords
						} : false
					)
				].filter(Boolean);
			},
			getLessonsWithSchedules () {
				return this.getLessons?.filter((lesson) => {
					return lesson?.schedule?.startsAt !== undefined &&
						lesson?.schedule?.startsAt !== null;
				});
			},
			getCompletedLessons () {
				return this.getLessons?.filter((lesson) => {
					return lesson?.schedule?.endedAt !== undefined &&
						lesson?.schedule?.endedAt !== null;
				});
			},
			getCourseIsComplete () {
				if (this.course?.live) {
					return this.course?.isComplete;
				}
				if (this.getContext !== 'myCourses') {
					return false;
				}
				return this.course.progress !== null;
			}
		},
		mounted () {
			this.init();
		},
		methods: {
			init () {
				if (this.$route.query?.completed) {
					this.$router.replace({ query: null });
					this.$store.commit('ui/showNotification', {
						notification: 'You can now view the hand records in Lesson resources.'
					});
				}
			},
			async joinCourse () {
				const course = await api.courses.joinCourseById({
					courseId: this.course.id
				});
				if (!course) {
					return false;
				}
				if (!course.checkout) {
					// no fee required, no stripe checkout
					this.$router.push(`/my-courses/${this.course.id}`);
					return;
				}
				const stripe = await getStripe();
				stripe.redirectToCheckout({
					sessionId: course.checkout
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='course-view'] {
		@include nested-grid;
		article {
			@include nested-cols(12, 12);
			margin-bottom:rem(64);
			@include mq('sm') {
				@include nested-cols(7, 12);
				@include nested-gut(12);
				margin-bottom:0;
			}
			[data-element='type-and-level'] {
				display:flex;
				margin:0 0 rem(42);
				&:last-child {
					margin-bottom:0;
				}
				[data-component='live-or-solo-badge'] {
					margin:0 rem(32) 0 0;
				}
				[data-component='experience-level-chart'] {
					margin:0 auto 0 0;
				}
			}
			[data-element='teacher'] {
				display:flex;
				align-items:center;
				margin:0 0 rem(8);
				&:last-child {
					margin-bottom:0;
				}
				&:hover {
					text-decoration:underline;
				}
				[data-component='icon'] {
					width:auto;
					height:rem(38);
					margin:0 rem(4) 0 0;
				}
				span {
					font-family:'Montserrat', sans-serif;
					font-size:rem(14);
					font-weight:700;
					color:$c-brand-grey;
				}
			}
			[data-element='heading'] {
				margin:0 0 rem(8);
				font-family:'Montserrat', sans-serif;
				font-size:rem(34);
				font-weight:700;
				color:$c-brand-blue;
				&:last-child {
					margin-bottom:0;
				}
			}
			[data-element='subheading'] {
				margin: 0 0 rem(58);
				font-size:rem(18);
				color:$c-brand-blue;
				&:last-child {
					margin-bottom:0;
				}
			}
			[data-element='introduction'] {
				margin:0 0 rem(38);
				&:last-child {
					margin-bottom:0;
				}
				h2 {
					margin-bottom:rem(12);
					font-family:'Montserrat', sans-serif;
					font-size:rem(18);
					font-weight:700;
				}
				::v-deep [data-element='introduction'] {
					font-size:rem(18);
					@include rich-text-content;
					@include rich-text-suits;
				}
			}
			[data-element='main-cta'] {
				display:flex;
				margin:0 0 rem(60);
				&:last-child {
					margin-bottom:0;
				}
				[data-component='btn'] {}
			}
			[data-component='lesson-list'] {
				margin-bottom:rem(64);
				&:last-child {
					margin-bottom:0;
				}
			}
			[data-component='course-action-groups'] {
			}
		}
		aside {
			@include nested-cols(12, 12);
			@include mq('sm') {
				@include nested-cols(4, 12);
				@include nested-push(1, 12);
			}
			[data-component='additional-info-list'] {
				margin-bottom:rem(22);
				&:last-child {
					margin-bottom:0;
				}
			}
			[data-component='course-action-list'] {
				margin-bottom:rem(26);
			}
			[data-element='badges'] {
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				margin-bottom:rem(24);
				&:last-child {
					margin-bottom:0;
				}
			}
		}
	}

</style>
